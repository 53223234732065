import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import mapboxgl from "mapbox-gl";
import { isDomAvailable } from "../../lib/util";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import {
  COMPOSITE,
  MAX_SELECTION_COUNT,
  SelectionMode,
} from "../../lib/constants";
import { toggleFeature, graphShowToggle, updateFeatureNames } from "../../actions";
import Legend from "./Legend";
import config from "../../config";
import addRiskStyle from "./helpers/addRiskStyle";
import updateOverlays from "./helpers/updateOverlays";
import updateLayers from "./helpers/updateLayers";
import addMapListeners from "./helpers/addMapListeners";
import Navbar from "./Navbar";
import { setMapLoading } from "../../actions";
import { postJSON } from "../../lib/util";
import addConflictLayer from "./helpers/addConflictLayer";

mapboxgl.accessToken = config.mapboxAccessToken;

global.feature_id_hovering = null;
global.mapOnMousemove = null;

const Map = (props) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [currentMapFeatures, setCurrentMapFeatures] = useState([]);
  const [conflictData, setConflictData] = useState(null);
  const {
    dispatch,
    layers: { data: layersList },
    features: featuresInState,
    layer_active,
    isMapLoading,
    products_active,
    products,
    date,
  } = props;
  const activeLayerName = layer_active?.name ?? null;
  const activeFeaturesList =
    props.features_active[layer_active?.name] &&
    Object.values(props.features_active[layer_active.name]);
  const atMaxFeatureSelection =
    activeFeaturesList?.length >= MAX_SELECTION_COUNT;
  const { t } = useTranslation();
  const { language } = useI18next();

  const conflictProductNames = useMemo(
    () =>
      products
        .filter((product) => product.category === "Conflict" && product.sidebar)
        .map((product) => product.name),
    [products]
  );

  const timeseriesNonConflictProducts = useMemo(
    () =>
      new Set(
        props.timeseries
          ?.map((p) => {
            if (p["product_id"].includes(COMPOSITE)) {
              return COMPOSITE;
            }
            if (
              conflictProductNames.find((name) =>
                p["product_id"].includes(name)
              )
            ) {
              return null;
            }
            return p["product_id"];
          })
          .filter((name) => name !== null)
      ),
    [props.timeseries, conflictProductNames]
  );

  const visibleNonConflictProduct = useMemo(() => {
    const hasActiveConflictProduct = products_active.find((name) =>
      conflictProductNames.includes(name)
    );

    // If only conflict selected, proceed as if no "selection"
    // Since conflict does not display chloropleth & has diff legend.
    // Catches for both multi-indicator or -region mode
    if (
      (products_active.length === 1 && hasActiveConflictProduct) ||
      conflictProductNames.includes(props.visible_tab)
    ) {
      return null;
    }

    // If non-conflict selected in multi-region mode, continue with first (only) selection
    if (
      props.selectionMode === SelectionMode.MultiRegions &&
      !hasActiveConflictProduct
    ) {
      return products_active[0];
    }

    // If conflict selected in multi-indicator mode with
    // one other non-conflict indicator, continue with
    // singular non-conflict indicator as if not composite.
    if (
      hasActiveConflictProduct &&
      props.visible_tab === COMPOSITE &&
      products_active.length === 3
    ) {
      // return the first non conflict product
      const nonConflict = products_active.find(
        (name) => name !== "composite" && !conflictProductNames.includes(name)
      );
      if (!!nonConflict) return nonConflict;
    }

    // If more than one non-conflict indicator
    // selected in multi-indicator mode, continue with visible_tab selection.
    return props.visible_tab;
  }, [
    conflictProductNames,
    props.visible_tab,
    products_active,
    products_active.length,
  ]);

  useEffect(() => {
    if (map && layer_active) {
      map.fitBounds(layer_active.bounds, { padding: 15 });
    }
  }, [map, layer_active]);

  useEffect(() => {
    const mapFeaturesById = Object.values({ ...currentMapFeatures }).reduce(
      (obj, feature) => {
        obj[feature.id] = feature.properties;
        return obj;
      },
      {}
    );
    mapFeaturesById &&
      Object.entries(featuresInState).forEach(([id, props]) => {
        const updatedFeatureProps = mapFeaturesById[id];
        if (
          updatedFeatureProps &&
          (updatedFeatureProps?.name_0 !== props?.name_0 ||
            updatedFeatureProps?.name_1 !== props?.name_1 ||
            updatedFeatureProps?.name_2 !== props?.name_2)
        ) {
          dispatch(updateFeatureNames(id, updatedFeatureProps));
        }
      });
  }, [currentMapFeatures, featuresInState, dispatch, language]);

  const activeConflictProductName = useMemo(
    () => products_active.find((name) => conflictProductNames.includes(name)),
    [products_active, conflictProductNames]
  );
  const activeConflictData = useMemo(
    () => products.find((p) => p.name === activeConflictProductName),
    [products, activeConflictProductName]
  );

  const hasVisibleProduct = !!(
    visibleNonConflictProduct || activeConflictProductName
  );

  useEffect(() => {
    if (!activeConflictProductName) {
      setConflictData(null);
    }
    if (date && layer_active && !!activeConflictProductName) {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      postJSON(
        `${config.api}/conflicts.json`,
        {
          product_name: activeConflictProductName,
          layer_name: layer_active.name,
          start_date: firstDay,
          end_date: lastDay,
        },
        (error, response) => {
          if (response?.data?.features?.length) {
            setConflictData(response.data);
          }
        },
        language
      );
    }
  }, [date, layer_active, activeConflictProductName]);

  const onFeatureClick = useCallback(
    (feature) => {
      const willExceedMaxSelection =
        !activeFeaturesList?.includes(feature.id) && atMaxFeatureSelection;
      // Prevent new selection if already at max feature selections
      if (hasVisibleProduct && !willExceedMaxSelection) {
        dispatch(toggleFeature(feature.id, feature.properties));
        dispatch(graphShowToggle(true));
      }
    },
    [dispatch, hasVisibleProduct, activeFeaturesList, atMaxFeatureSelection]
  );

  useEffect(() => {
    if (map != null || !mapRef.current) {
      updateLayers(map, layersList, activeLayerName);
    } else {
      if (layer_active) {
        // Add sources for all layers on load
        const allLayerNames = layersList.map((l) => l.name).join("_");
        const newMap = new mapboxgl.Map({
          container: mapRef.current,
          style: config.mapboxStyleId,
          bounds: layer_active.bounds,
          fitBoundsOptions: {
            padding: 15,
          },
          maxZoom: 8,
          transformRequest: (url, resourceType) => {
            if (
              resourceType === "Tile" &&
              url.includes(`/api/mvt/${allLayerNames}/`)
            ) {
              return {
                url: url,
                headers: { "Accept-Language": language },
              };
            }
          },
        });
        newMap.addControl(
          new mapboxgl.NavigationControl({
            showCompass: false,
            visualizePitch: false,
          }),
          "top-left"
        );
        newMap.on("load", () => {
          dispatch(setMapLoading(true));
          newMap.addSource("features", {
            type: "vector",
            tiles: [`${config.api}/mvt/${allLayerNames}/{z}/{x}/{y}.pbf`],
          });
          updateLayers(newMap, layersList, activeLayerName);
          setMap(newMap);
          newMap.resize();
        });
        newMap.on("idle", () => {
          dispatch(setMapLoading(false));
          const features = newMap.querySourceFeatures("features", {
            sourceLayer: activeLayerName,
          });
          setCurrentMapFeatures(features);
        });
        global.DEBUG_MAP_HANDLE = newMap;
      }
    }
  }, [
    mapRef,
    map,
    layersList,
    layer_active,
    activeLayerName,
    language,
    dispatch,
  ]);

  // Watch for graph being expanded/closed and resize map to fit container
  useEffect(() => {
    if (map) {
      map.resize();
    }
  }, [map, props.graph_show, props.graph_expand]);

  useEffect(() => {
    if (!map || isMapLoading) {
      return;
    }

    layersList.forEach((layer) => {
      const activeLayerFeatures = props.features_active[layer.name] ?? [];
      map
        .querySourceFeatures("features", {
          sourceLayer: layer.name,
        })
        .forEach((feature) => {
          map.setFeatureState(
            {
              source: "features",
              sourceLayer: layer.name,
              id: feature.id,
            },
            { selected: activeLayerFeatures.includes(feature.id) }
          );
          map.setFeatureState(
            {
              source: "features",
              sourceLayer: layer.name + "-labels",
              id: feature.id,
            },
            { selected: activeLayerFeatures.includes(feature.id) }
          );
        });
    });
  }, [map, isMapLoading, layersList, props.features_active]);

  useEffect(() => {
    if (
      map &&
      !isMapLoading &&
      products_active.length &&
      timeseriesNonConflictProducts.has(visibleNonConflictProduct)
    ) {
      addRiskStyle(
        map,
        visibleNonConflictProduct,
        date,
        activeLayerName,
        props.features_active,
        props.timeseries,
        props.timeseries_feature
      );
      return;
    }
    if (map && activeLayerName) {
      map.setPaintProperty(activeLayerName + "-fill", "fill-color", "#444");
      map.setPaintProperty(activeLayerName + "-fill", "fill-opacity", 0.2);
    }
  }, [
    map,
    isMapLoading,
    timeseriesNonConflictProducts,
    visibleNonConflictProduct,
    date,
    props.features_active,
    products_active,
    props.timeseries,
    props.timeseries_feature,
    activeLayerName,
    props.timeseries_loading,
  ]);

  useEffect(() => {
    if (map) {
      addMapListeners(
        global,
        map,
        activeLayerName,
        props.features_active,
        hasVisibleProduct,
        t,
        onFeatureClick,
        activeConflictData
      );
    }
  }, [
    t,
    map,
    global,
    activeLayerName,
    props.features_active,
    visibleNonConflictProduct,
    onFeatureClick,
    activeConflictData,
  ]);

  useEffect(
    () =>
      updateOverlays(
        map,
        props.products,
        products_active,
        props.products_overlays,
        props.products_overlays_active,
        props.overlays_opacities
      ),
    [
      map,
      props.products,
      products_active,
      props.products_overlays,
      props.products_overlays_active,
      props.overlays_opacities,
    ]
  );

  useEffect(() => addConflictLayer(map, conflictData), [map, conflictData]);
  return isDomAvailable ? (
    <div className="map" ref={mapRef}>
      <div className="info-container">
        <Navbar />
        <Legend
          {...props}
          visibleNonConflictProduct={visibleNonConflictProduct}
        />
      </div>
      {props.children}
    </div>
  ) : (
    <div>{t("Loading...")}</div>
  );
};

export default Map;
